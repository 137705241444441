import { SharedoTypesTree } from "@/services/sharedo/sharedoTypesTree";

class WorkItemRouteProvider {
    constructor() {
        this.__typesTree = new SharedoTypesTree();
        this.__loaded = this.__typesTree.load();
    }

    async getFor(sharedo) { }
}

class MappedRouteProvider extends WorkItemRouteProvider {
    constructor(map) {
        super();

        this.__map = map;
    }

    async getFor(sharedo) {
        await this.__loaded;

        for (const item of this.__map) {
            if (this.__typesTree.isDerivedFrom(sharedo.type.systemName, item.types)) {
                const route = { ...item.route };
                route.params = { ...route.params, id: sharedo.id }

                return route
            }
        }

        return null;
    }
}

export {
    MappedRouteProvider
}